<template>
  <div class="mt-2">
    <b-row align-h="center" v-if="staffLoading">
      <b-spinner
        class="m-1"
        type="grow"
        variant="primary"
        label="Spinning"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </b-row>

    <template v-else>
      <form-wizard
        ref="wizard"
        color="#7367F0"
        title=""
        subtitle=""
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="steps-transparent mb-2"
      >
        <tab-content title="Classes" icon="feather icon-smartphone">
          <b-row class="mb-1">
            <b-col md="12">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="44"
                    :src="myObj.picture"
                    variant="light-primary"
                  />
                </template>
                <b-link class="font-weight-bold d-block text-nowrap mb-50">
                  <b-badge variant="light-primary">
                    {{ myObj.name }}
                  </b-badge>
                </b-link>
                <small class="text-muted">
                  <b-badge variant="light-primary">
                    {{ myObj.designation }}
                  </b-badge>
                </small>
              </b-media>
            </b-col>
          </b-row>
          <b-card class="">
            <h3 class="mt-1">Teaching Classes</h3>
            <hr />
            <b-row>
              <b-col md="3">
                <b-form-group
                  label="Class"
                  invalid-feedback="Class is required."
                  ref="tcID"
                >
                  <v-select
                    v-model="teachingClassObj.clsID"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="classesOptions"
                    :clearable="false"
                    :reduce="(val) => val.id"
                    label="name"
                    input-id="id"
                    ref="tcID"
                    @input="FillSections()"
                    @search:blur="CheckTcID()"
                    :disabled="dataloading"
                    placeholder="Select class"
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Section"
                  invalid-feedback="Section is required."
                  ref="tSection"
                >
                  <v-select
                    ref="tSection"
                    multiple
                    :closeOnSelect="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    v-model="selectedSections"
                    :options="admittedSections"
                    :reduce="(val) => val.id"
                    label="section"
                    :clearable="false"
                    :disabled="dataloading"
                    @input="setSections()"
                    @search:blur="CheckTSection()"
                    placeholder="Select section"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  label="Subject"
                  invalid-feedback="Subject is required."
                  ref="tSubjects"
                >
                  <v-select
                    ref="tSubjects"
                    multiple
                    :closeOnSelect="false"
                    v-model="selectedSubs"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="subjects"
                    :reduce="(val) => val.id"
                    label="subject"
                    :clearable="false"
                    :disabled="dataloading"
                    @input="setSubjects()"
                    @search:blur="CheckTSubject()"
                    placeholder="Select assigned subject."
                  />
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group label="" class="mt-2">
                  <b-button
                    class="btn-icon"
                    variant="success"
                    @click="assign()"
                    :disabled="assigning || gridLoading"
                    block
                  >
                    <b-spinner v-if="assigning" small />
                    <span v-else>Save</span>
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-table
                  class="mt-1"
                  ref="selectableTable"
                  :items="gridData"
                  :fields="fields"
                  :busy="gridLoading"
                  show-empty
                  responsive
                >
                  <template #table-busy>
                    <div class="d-flex justify-content-center my-2">
                      <b-spinner
                        style="width: 3rem; height: 3rem"
                        type="grow"
                        variant="primary"
                        label="Spinning"
                      ></b-spinner>
                    </div>
                  </template>
                  <template #cell(subjects)="data">
                    <b-badge
                      variant="light-primary"
                      v-for="sub in data.item.subjects"
                      :key="sub.id"
                      style="margin: 2px"
                    >
                      <span>{{ sub.subject }}</span>
                      <feather-icon
                        icon="XIcon"
                        class="ml-50 cursor-pointer"
                        @click="removeSingle(data.item, sub)"
                      />
                    </b-badge>
                  </template>
                  <template #cell(actions)="data">
                    <div class="d-flex">
                      <b-button
                        v-if="data.item.isClassTeacher"
                        variant="outline-danger"
                        class="btn-icon mr-1"
                        @click="setTeacher(data.item, false)"
                        v-b-tooltip.hover.left
                        title="Remove as Class Teacher"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                      <b-button
                        v-else
                        variant="outline-success"
                        class="btn-icon mr-1"
                        @click="setTeacher(data.item, true)"
                        v-b-tooltip.hover.left
                        title="Mark as Class Teacher"
                      >
                        <feather-icon icon="CheckIcon" />
                      </b-button>
                      <b-button
                        variant="outline-danger"
                        class="btn-icon"
                        @click="DeleteSub(data.item)"
                        v-b-tooltip.hover.left
                        title="Delete"
                      >
                        <feather-icon icon="Trash2Icon" />
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </b-col>
            </b-row>
          </b-card>
        </tab-content>

        <tab-content title="App Settings" icon="feather icon-settings">
          <b-row class="mb-1">
            <b-col md="12">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="44"
                    :src="myObj.picture"
                    variant="light-primary"
                  />
                </template>

                <b-link class="font-weight-bold d-block text-nowrap mb-50">
                  <b-badge variant="light-primary">
                    {{ myObj.name }}
                  </b-badge>
                </b-link>
                <small class="text-muted">
                  <b-badge variant="light-primary">
                    {{ myObj.designation }}
                  </b-badge>
                </small>
              </b-media>
            </b-col>
          </b-row>
          <b-row class="match-height">
            <b-col md="12">
              <b-card class="">
                <h3 class="mt-1">Application Rights</h3>
                <hr />
                <b-row>
                  <b-col v-if="rightLoading" class="text-center my-1" md="12">
                    <b-spinner
                      type="grow"
                      variant="primary"
                      label="Spinning"
                    ></b-spinner>
                  </b-col>
                  <template v-else>
                    <b-col class="d-flex align-items-center flex-wrap" md="12">
                      <b-form-checkbox-group
                        v-model="selectedRights"
                        :options="appRights"
                      ></b-form-checkbox-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group label="" class="mt-2">
                        <b-button
                          variant="success"
                          :disabled="savingRights"
                          @click="saveRights()"
                        >
                          <b-spinner v-if="savingRights" small />
                          <span v-else>Save</span>
                        </b-button>
                      </b-form-group>
                    </b-col>
                  </template>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </tab-content>
        <template slot="footer" slot-scope="props">
          <div class="wizard-footer-left d-none"></div>
          <div class="wizard-footer-right d-none"></div>
        </template>
      </form-wizard>
    </template>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import vSelect from "vue-select";
import {
  VBTooltip,
  BFormCheckboxGroup,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BMedia,
  BLink,
  BSpinner,
  BBadge,
  BTable,
  BForm,
  BSidebar,
  BAvatar,
} from "bootstrap-vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    FormWizard,
    TabContent,
    vSelect,
    BFormCheckboxGroup,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCard,
    BButton,
    BMedia,
    BLink,
    BSpinner,
    BBadge,
    BTable,
    BForm,
    BSidebar,
    BAvatar,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      staffLoading: false,
      dataloading: false,
      gridLoading: false,
      myObj: {},
      gridData: [],
      fields: [
        { label: "Class", key: "cls" },
        { label: "section", key: "sec" },
        { label: "subject", key: "subjects" },
        "actions",
      ],
      subObj: null,
      teachingClassObj: {},
      classes: [],
      classesOptions: [],
      selectedSubs: [],
      subjects: [],
      selectedSections: [],
      admittedSections: [],
      assigning: false,
      appRights: [
        { text: "Homework", value: "Homework" },
        { text: "Attendance", value: "Attendance" },
        { text: "Examination", value: "Examination" },
        { text: "Resources", value: "Resources" },
        { text: "Online Class", value: "Online Class" },
        { text: "Complaint", value: "Complaint" },
        { text: "Parent Consent", value: "Parent Consent" },
      ],
      selectedRights: [
        "Homework",
        "Attendance",
        "Examination",
        "Resources",
        "Online Class",
        "Complaint",
        "Parent Consent",
      ],
      savingRights: false,
      rightObj: {},
      rightLoading: false,
    };
  },
  created() {
    // let right = this.$store.state.rights["journal"];

    this.$route.params.id = parseInt(this.$route.params.id);
    // && rights.assign
    if (this.$route.params.id > 0) {
      this.LoadStaff();
    } else {
      this.$router.replace({
        name: "misc-not-authorized",
      });
    }
  },
  methods: {
    ...mapActions({
      get: "get",
      post: "post",
      postObj: "postObj",
      put: "put",
      delete: "delete",
    }),

    CheckTcID() {
      var elem = this.$refs["tcID"];
      if (this.teachingClassObj.clsID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTSection() {
      var elem = this.$refs["tSection"];
      if (this.selectedSections.length === 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTSubject() {
      var elem = this.$refs["tSubjects"];
      if (this.selectedSubs.length === 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },

    FillSections(text) {
      let obj = this.classes.find(
        (el) => el.cls.id == this.teachingClassObj.clsID
      );
      if (obj && obj.sections.length > 0) {
        this.admittedSections = [...obj.sections];
        this.admittedSections.unshift({ section: "All Sections", id: 0 });
      } else this.admittedSections = [];

      if (text != "filled") this.selectedSections = [];
      // console.log("Thisis", this.admittedSections);

      if (this.subObj && this.subObj.valueBool) {
        this.selectedSubs = [];
        this.subjects = [];
        this.LoadByClass();
      }
    },

    setSections() {
      if (this.selectedSections.at(-1) == 0) {
        this.selectedSections = [0];
      } else {
        this.selectedSections = this.selectedSections.filter((el) => el != 0);
      }
      // console.log(this.selectedSections);
    },
    setSubjects() {
      if (this.selectedSubs.at(-1) == 0) {
        this.selectedSubs = [0];
      } else {
        this.selectedSubs = this.selectedSubs.filter((el) => el != 0);
      }
      // console.log(this.selectedSubs);
    },

    async assign() {
      this.teachingClassObj.tID = parseInt(this.$route.params.id);
      // console.log(this.teachingClassObj);

      this.CheckTcID();
      this.CheckTSubject();
      this.CheckTSection();
      if (
        this.CheckTcID() == false ||
        this.CheckTSubject() == false ||
        this.CheckTSection() == false
      ) {
        return this.$bvToast.toast("Please enter all the required fields.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        this.assigning = true;

        if (this.selectedSections[0] == 0) {
          let secArr = this.classes.find(
            (el) => el.cls.id === this.teachingClassObj.clsID
          ).sections;
          let secs = secArr.reduce((acc, el) => {
            acc.push(el.id);
            return acc;
          }, []);
          this.teachingClassObj.sections = secs;
        } else {
          this.teachingClassObj.sections = this.selectedSections;
        }

        // console.log(this.teachingClassObj.sections);

        if (this.selectedSubs[0] == 0) {
          let subs = this.subjects.reduce((acc, el) => {
            if (el.id != 0) acc.push(el.id);
            return acc;
          }, []);
          this.teachingClassObj.subjects = subs;
        } else {
          this.teachingClassObj.subjects = this.selectedSubs;
        }
        // console.log(this.teachingClassObj.subjects);

        var status = await this.post({
          url:
            this.$store.state.domain +
            "TeacherClasses/SaveData?db=" +
            this.$store.state.userData.db,
          body: this.teachingClassObj,
          message: "Classes assigned successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) {
          this.LoadTeachingClasses();
          this.teachingClassObj = {
            tID: this.$route.params.id,
            clsID: 0,
            sections: [],
            subjects: [],
            campusID: this.myObj.campusID,
          };
          this.selectedSections = [];
          this.selectedSubs = [];
          this.admittedSections = [];
        }
        this.assigning = false;
      }
    },
    async setTeacher(item, val) {
      let text = "Do you want to mark as Class Teacher?";
      if (!val) text = "Do you want to remove as Class Teacher?";
      let result = await Swal.fire({
        title: "Are you sure?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes",
      });

      if (result.isConfirmed) {
        let msg = "Marked as class teacher successfully!";
        if (!val) msg = "Removed as class teacher successfully!";
        var status = await this.post({
          url:
            this.$store.state.domain +
            "TeacherClasses/setClassTeacher?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.myObj.campusID +
            "&tID=" +
            item.tID +
            "&clsID=" +
            item.clsID +
            "&secID=" +
            item.secID +
            "&val=" +
            val,
          body: null,
          message: msg,
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadTeachingClasses();
      }
    },
    async DeleteSub(item) {
      // console.log(item);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "Staff/DeleteSubjects?db=" +
            this.$store.state.userData.db +
            "&cID=" +
            this.myObj.campusID +
            "&clsID=" +
            item.clsID +
            "&secID=" +
            item.secID +
            "&tID=" +
            item.tID,
          body: null,
          message: "subject removed successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadTeachingClasses();
      }
    },
    async removeSingle(row, sub) {
      // console.log(row, sub);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "TeacherClasses/" +
            sub.id +
            "?db=" +
            this.$store.state.userData.db,
          body: null,
          message: "subject removed successfully!",
          context: this,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadTeachingClasses();
      }
    },
    async saveRights() {
      this.rightObj.rights = this.selectedRights.filter((el) => el).join(",");
      // console.log(this.selectedRights, this.rightObj);
      if (this.rightObj.id == 0) {
        this.savingRights = true;
        var status = await this.post({
          url:
            this.$store.state.domain +
            "TeachersAppRights?db=" +
            this.$store.state.userData.db,
          body: this.rightObj,
          message: "App rights saved successfully.",
          context: this,
          token: this.$store.state.userData.token,
        });
        this.savingRights = false;
        if (status) this.LoadAppRights();
      } else {
        this.savingRights = true;
        var status = await this.put({
          url:
            this.$store.state.domain +
            "TeachersAppRights/" +
            this.rightObj.id +
            "?db=" +
            this.$store.state.userData.db,
          message: "App rights saved successfully.",
          context: this,
          body: this.rightObj,
          token: this.$store.state.userData.token,
        });
        this.savingRights = false;
        if (status) this.LoadAppRights();
      }
    },
    async LoadSettings() {
      let res = await this.post({
        url:
          this.$store.state.domain +
          "Settings/getSettings?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.myObj.campusID,
        body: ["subject_class"],
        context: this,
        token: this.$store.state.userData.token,
        subjects: true,
      });
      this.subObj = res.find((el) => el.key == "subject_class");

      if (this.subObj && this.subObj.valueBool) {
        this.subjects = [];
      } else this.LoadSubject();
    },
    async LoadSubject() {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.myObj.campusID,
        token: this.$store.state.userData.token,
      };
      this.subjects = await this.get(obj);
      if (this.subjects.length > 0) {
        this.subjects.unshift({ subject: "All Subjects", id: 0 });
      }

      // console.log(this.subjects);
    },
    async LoadByClass() {
      var obj = {
        url:
          this.$store.state.domain +
          "Subjects/LoadSubjectClassData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.myObj.campusID +
          "&clsID=" +
          this.teachingClassObj.clsID,
        token: this.$store.state.userData.token,
      };
      this.subjects = await this.get(obj);
      if (this.subjects.length > 0) {
        this.subjects.unshift({ subject: "All Subjects", id: 0 });
      }
      // console.log(this.subjects);
    },
    async LoadInfo() {
      this.dataloading = true;

      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.myObj.campusID +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = this.classes.map((el) => el.cls);

      this.dataloading = false;
    },

    async LoadTeachingClasses() {
      this.gridLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "TeacherClasses/LoadDataWebNew?db=" +
          this.$store.state.userData.db +
          "&tID=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };
      this.gridData = await this.get(obj);
      // console.log("grid", this.gridData);
      this.gridLoading = false;
    },
    async LoadAppRights() {
      this.rightLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "TeachersAppRights/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.myObj.campusID +
          "&tID=" +
          this.$route.params.id,
        token: this.$store.state.userData.token,
      };
      this.rightObj = await this.get(obj);
      this.rightLoading = false;

      if (!this.rightObj) {
        this.rightObj = {
          id: 0,
          tID: this.$route.params.id,
          rights: "",
          campusID: this.myObj.campusID,
        };
        this.selectedRights = [
          "Homework",
          "Attendance",
          "Examination",
          "Resources",
          "Online Class",
          "Complaint",
          "Parent Consent",
        ];
      } else {
        this.selectedRights = this.rightObj.rights
          ? this.rightObj.rights.split(",")
          : [];
      }
    },
    async LoadStaff() {
      this.staffLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "Staff/GetSelected?id=" +
          this.$route.params.id +
          "&db=" +
          this.$store.state.userData.db,
        token: this.$store.state.userData.token,
      };
      this.myObj = await this.get(obj);
      //   console.log(this.myObj);
      if (this.myObj.id) {
        this.LoadAppRights();
        this.LoadSettings();
        this.LoadInfo();
        this.LoadTeachingClasses();
        this.teachingClassObj = {
          tID: this.$route.params.id,
          clsID: 0,
          sections: [],
          subjects: [],
          campusID: this.myObj.campusID,
        };
        this.staffLoading = false;
        this.$nextTick(() => {
          this.$refs.wizard.activateAll();
        });
      } else {
        this.staffLoading = false;
        this.$router.replace({ name: "manage-staffgrid" }).then(() => {
          this.$bvToast.toast("No record found!", {
            title: "Error!",
            variant: "danger",
            solid: true,
          });
        });
      }
    },
  },
};
</script>
<style></style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
